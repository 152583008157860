import type { About } from "~/lib/models";

export const ABOUT: About[] = [
    {
        uuid: "1",
        imgUrl: "/images/aboutUs/diamond.svg",
        title: "Compromisso e  Profissionalismo",
        description:
            "O nosso compromisso para com a saúde e bem estar animal valeu-nos a confiança da Royal Canin. Somos os distribuidores exclusivos para Angola.",
        color: "text-primary",
    },
    {
        uuid: "2",
        imgUrl: "/images/aboutUs/box.svg",
        title: "Capacidade Logística",
        description:
            "A nossa capacidade logística permite-nos fazer aquisições rápidas e constantes, repor o nosso stock em questão de dias e oferecer produtos de qualidade a preços competitivos.",
        color: "text-blue-700",
    },
    {
        uuid: "3",
        imgUrl: "/images/aboutUs/medal.svg",
        title: "Know How",
        description:
            "Prestamos aconselhamento personalizado e especializado sobre questões relacionadas com produtos que distribuímos: o que são, como e quando utilizá-los",
        color: "text-blue-600",
    },
    {
        uuid: "4",
        imgUrl: "/images/aboutUs/cart.svg",
        title: "Entregas Simplificadas",
        description:
            "Processo de encomenda rápido e fácil, com recurso a tecnologia, e entregas em Luanda, Benguela e Huíla.",
        color: "text-blue-800",
    },
];
