import { AntboxError, Either, Node, NodeFilter, NodeServiceClient, left, right } from "../deps";
import type { FeaturedProduct, PaginationOptions } from "../models";

export class FeaturedProductService {
    #FEATURED_PRODUCT_UUID = "featured-product";

    constructor(private readonly nodeServiceClient: NodeServiceClient, private readonly tenant: string) {}

    async list(options?: PaginationOptions): Promise<Either<AntboxError, FeaturedProduct[]>> {
        const perPage = options?.perPage ?? 2;
        const page = options?.page ?? 1;
        const query: NodeFilter[] = [["aspects", "contains", this.#FEATURED_PRODUCT_UUID]];
        const featuredProducts: FeaturedProduct[] = [];
        const featuredProductsOrErr = await this.nodeServiceClient.query(query, perPage, page);

        if (featuredProductsOrErr.isLeft()) {
            return left(featuredProductsOrErr.value);
        }

        if (featuredProductsOrErr.isRight()) {
            featuredProductsOrErr.value.nodes.forEach((n) => {
                featuredProducts.push(this.#convertNodeToFeaturedProductModel(n));
            });
        }

        return right(featuredProducts);
    }

    #convertNodeToFeaturedProductModel(node: Node): FeaturedProduct {
        return {
            id: node.uuid,
            title: node.title,
            description: node.properties?.[`${this.#FEATURED_PRODUCT_UUID}:description`] as string,
            imgUrl: this.#getImageUrl(node.uuid),
        };
    }

    #getImageUrl (uuid: string) {
        const url = this.nodeServiceClient.getNodeUrl(uuid)
        return `${url}?x-tenant=${this.tenant}`
    }
}
