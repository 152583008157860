<script lang="ts" setup>
import { ABOUT, BRANDS } from "~/lib/data";
import type { Banner, Event, Testimonial, FeaturedProduct } from "~/lib/models";
import { BannerService } from "~/lib/services/bannerService";
import { EventService } from "~/lib/services/eventService";
import { FeaturedProductService } from "~/lib/services/featuredProductService";
import { TestimonialService } from "~/lib/services/testimonialService";
import { handleEither, handleException } from "~/lib/helpers/handlers";

const inProduction = useProduction();
const events = ref<Event[]>();
const featuredProducts = ref<FeaturedProduct[]>();
const banners = ref<Banner[]>([]);
const testimonials = ref<Testimonial[]>();
const antbox = useAntbox();
const eventService = new EventService(antbox.antboxNodeServiceClient, antbox.tenant);
const bannerService = new BannerService(antbox.antboxNodeServiceClient, antbox.tenant);
const featuredProductService = new FeaturedProductService(antbox.antboxNodeServiceClient, antbox.tenant);
const testimonialService = new TestimonialService(antbox.antboxNodeServiceClient, antbox.tenant);

await bannerService
    .listHome()
    .then(handleEither((v) => (banners.value = v)))
    .catch(handleException);

await eventService
    .list()
    .then(handleEither((v) => (events.value = v.events)))
    .catch(handleException);

await featuredProductService
    .list()
    .then(handleEither((v) => (featuredProducts.value = v)))
    .catch(handleException);

await testimonialService
    .list()
    .then(handleEither((v) => (testimonials.value = v)))
    .catch(handleException);
</script>

<template>
    <main>
        <ClientOnly>
            <BannerCarousel v-if="banners.length > 0" :banners="banners" :compact="false" />
            <div v-else class="w-full h-[28rem] lg:h-[45.4rem] bg-grey-300 animate-pulse"></div>
        </ClientOnly>

        <div class="info">
            <h2>Somos o seu parceiro na distribuição de produtos veterinários e de nutrição animal</h2>
        </div>

        <section v-if="!inProduction">
            <ClientOnly>
                <div v-if="featuredProducts && featuredProducts.length > 0">
                    <div class="section-content lg:flex lg:items-center lg:h-[24rem] lg:flex-row-reverse">
                        <div class="overflow-hidden lg:w-1/2 h-full">
                            <img
                                v-if="featuredProducts[0].imgUrl"
                                class="w-full h-full object-cover"
                                :src="featuredProducts[0].imgUrl"
                            />
                            <div
                                v-else
                                class="w-96 h-52 lg:w-full m-auto lg:h-full lg:m-0 bg-grey-300 animate-pulse"
                            ></div>
                        </div>
                        <div
                            class="lg:pl-36 flex flex-1 items-center justify-center gap-4 flex-col text-center lg:text-left text-blue-900 py-[1.25rem] lg:items-start"
                        >
                            <h2 class="text-primary w-52 lg:w-[18rem] mt-2">{{ featuredProducts[0].title }}</h2>
                            <p class="lg:w-[20rem] mb-8 lg:mb-2 font-medium">
                                {{ featuredProducts[0].description }}
                            </p>
                            <VButton class="mb-8" color="bg-primary" text="Saber mais" />
                        </div>
                    </div>

                    <div
                        v-if="featuredProducts.length > 1"
                        class="section-content lg:flex lg:items-center lg:h-[24rem]"
                    >
                        <div class="overflow-hidden lg:w-1/2 h-full">
                            <img
                                v-if="featuredProducts[1].imgUrl"
                                class="w-full h-full object-cover"
                                :src="featuredProducts[1].imgUrl"
                            />
                            <div
                                v-else
                                class="w-96 h-52 lg:w-full m-auto lg:h-full lg:m-0 bg-grey-300 animate-pulse"
                            ></div>
                        </div>
                        <div
                            class="flex flex-1 items-center justify-center gap-4 flex-col text-center lg:text-left text-blue-900 py-[1.25rem] lg:items-start lg:pl-36"
                        >
                            <h2 class="text-primary w-52 lg:w-[18rem] mt-2">{{ featuredProducts[1].title }}</h2>
                            <p class="lg:w-[20rem] mb-8 lg:mb-2 font-medium">
                                {{ featuredProducts[1].description }}
                            </p>
                            <VButton class="mb-8" color="bg-primary" text="Saber mais" />
                        </div>
                    </div>
                </div>

                <div
                    v-else
                    class="flex flex-col gap-7 items-center relative lg:flex-row lg:gap-0 lg:justify-center animate-pulse"
                >
                    <div class="w-full max-w-md h-36 bg-grey-300 mt-8 lg:mx-8"></div>
                    <div class="flex w-full max-w-lg p-[1.25rem] flex-col justify-center gap-4">
                        <span class="w-full h-7 bg-grey-300 m-auto lg:m-0"></span>
                        <span class="w-4/5 h-4 bg-grey-300 m-auto lg:m-0"></span>
                        <span class="w-4/5 h-4 bg-grey-300 m-auto lg:m-0"></span>
                    </div>
                </div>
            </ClientOnly>

            <NuxtLink to="/products/category/all">
                <VButton
                    v-if="!inProduction"
                    class="block m-auto mt-4 lg:mt-16"
                    text="Ver todos os produtos"
                    color="bg-blue-900"
                />
            </NuxtLink>
        </section>

        <section>
            <div
                class="section-content flex flex-col justify-between items-center gap-8 my-8 py-5 lg:flex-row lg:flex-wrap lg:items-start"
            >
                <div v-for="subject in ABOUT" class="lg:flex lg:gap-8 lg:w-[46%]">
                    <div class="lg:w-[8rem] lg:h-[8rem]">
                        <img class="m-auto block lg:w-full" :src="subject.imgUrl" alt="" />
                    </div>
                    <div
                        class="flex flex-col text-center items-center lg:block lg:text-left lg:w-full"
                        :class="subject.color"
                    >
                        <h2 class="my-3 w-60 lg:w-auto lg:mt-0" :class="subject.color">
                            {{ subject.title }}
                        </h2>
                        <p class="text-blue-900 font-medium">{{ subject.description }}</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="info" id="brands">
            <div class="section-content flex gap-8 items-center flex-col">
                <h2>As nossas marcas</h2>
                <div class="flex items-center justify-center gap-8 lg:justify-between flex-wrap w-full">
                    <img v-for="brand in BRANDS" :key="brand" :src="brand" alt="" class="opacity-70 object-cover" />
                </div>
            </div>
        </section>

        <section v-if="!inProduction">
            <div class="section-content">
                <TestimonialCarousel v-if="testimonials && testimonials.length > 0" :testimonials="testimonials" />
                <div v-else class="w-full h-[20rem] lg:h-[31.2rem] bg-grey-400 animate-pulse"></div>
            </div>
        </section>

        <section v-if="!inProduction" class="info">
            <h2>Eventos</h2>
        </section>

        <section v-if="!inProduction">
            <div
                v-if="events && events.length > 0"
                class="section-content flex flex-col md:flex-row flex-wrap gap-8 w-full md:justify-center my-6"
            >
                <VEvent :event="events[0]" />
                <VEvent v-if="events.length > 1" :event="events[1]" />
                <div class="hidden sm:flex md:hidden lg:flex">
                    <VEvent v-if="events.length > 2" :event="events[2]" />
                </div>
            </div>

            <div class="section-content mb-4 animate-pulse flex gap-4 flex-wrap justify-center my-6" v-else>
                <div class="flex md:flex-col gap-4 px-4 py-2 md:py-4 md:w-[22.5rem]">
                    <div class="w-36 h-36 md:w-full md:h-40 bg-grey-500"></div>

                    <div class="flex flex-col gap-2 md:gap-4 flex-1 md:relative">
                        <span class="mt-1 h-4 w-28 bg-grey-500"></span>

                        <h2 class="md:m-aut h-6 w-56 bg-grey-500"></h2>

                        <span class="p-1 md:absolute top-0 right-0 w-24 h-4 bg-grey-500"></span>

                        <p class="hidden md:block w-full h-4 bg-grey-500"></p>

                        <div class="hidden md:flex flex-1 justify-end items-end">
                            <span class="h-4 w-28 bg-grey-500"></span>
                        </div>
                    </div>
                </div>

                <div class="flex md:flex-col gap-4 px-4 py-2 md:py-4 md:w-[22.5rem]">
                    <div class="w-36 h-36 md:w-full md:h-40 bg-grey-500"></div>

                    <div class="flex flex-col gap-2 md:gap-4 flex-1 md:relative">
                        <span class="mt-1 h-4 w-28 bg-grey-500"></span>

                        <h2 class="md:m-aut h-6 w-56 bg-grey-500"></h2>

                        <span class="p-1 md:absolute top-0 right-0 w-24 h-4 bg-grey-500"></span>

                        <p class="hidden md:block w-full h-4 bg-grey-500"></p>

                        <div class="hidden md:flex flex-1 justify-end items-end">
                            <span class="h-4 w-28 bg-grey-500"></span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>
